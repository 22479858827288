import React, { useEffect, useState } from 'react';
import ImageList from './ImageList';
import Image from './Image';
import axios from 'axios';
import MDEditor from '@uiw/react-md-editor';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Admin = () => {
  const [sections, setSections] = useState([]);
  const [images, setImages] = useState([]);
  const [pageImages, setPageImages] = useState([]);
  const [imageOrders, setImageOrders] = useState({});

  const [imageName, setImageName] = useState([]);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleOrderChange = (imageName, order) => {
    setImageOrders({ ...imageOrders, [imageName]: order });
  };

  const fetchPageImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/pageimages`
      );
      setPageImages(response.data);
      let imgOrd = {};
      for (let i = 0; i < response.data.length; i++) {
        imgOrd[response.data[i].name] = response.data[i].ord;
      }
      setImageOrders(imgOrd);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchPost = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/sections`
      );
      setSections(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.fonts.ready.then(() => setFontsLoaded(true));
    fetchPost();
    fetchPageImages();
    fetchImages();
    setLoaded(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_BACKEND}/pageimages`;
    const method = 'POST';

    try {
      const response = await axios({
        method: method,
        url: url,
        data: { name: imageName },
        headers: { 'Content-Type': 'application/json' },
      });
      const data = response.data;
      setPageImages((pageImages) => [...pageImages, data]);
      toast.success('Section updated successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleImageInput = (id, newImageUrl) => {
    if (newImageUrl == '') {
      return;
    }
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, image: newImageUrl } : section
      )
    );
  };

  const handleSectionUpdate = async (id) => {
    try {
      const sectionToUpdate = sections.find((section) => section.id === id);
      // const image = document.querySelector(`#newsectionimage-${id}`);
      let updatedSection;
      // if (image && image.value != '') {
      if (sectionToUpdate.image) {
        updatedSection = await axios.put(
          `${process.env.REACT_APP_BACKEND}/sections/${id}`,
          {
            content: sectionToUpdate.content,
            image: sectionToUpdate.image,
          }
        );
      } else {
        updatedSection = await axios.put(
          `${process.env.REACT_APP_BACKEND}/sections/${id}`,
          {
            content: sectionToUpdate.content,
          }
        );
      }
      setSections((prevSections) =>
        prevSections.map((section) =>
          section.id === id ? updatedSection.data : section
        )
      );
      toast.success('Section updated successfully!');
    } catch (err) {
      console.error('Error updating section:', err);
      toast.error('Failed to update section. Please try again.');
    }
  };

  const handleSectionContentChange = (id, newContent) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, content: newContent } : section
      )
    );
  };

  const fetchImages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/imagelist`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }
      const data = await response.json();
      setImages(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDelete = async (name) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_BACKEND}/pageimages/${name}`
      );
      setPageImages(pageImages.filter((img) => img.name !== name));
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleImageUpdate = async (name) => {
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_BACKEND}/pageimages/${name}`,
        {
          ord: imageOrders[name],
        }
      );
      toast.success('order updated successfully!');
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update order. Please try again.');
    }
  };

  if (!loaded) {
    return <div></div>;
  }

  return (
    <div className="container mt-4">
      <Toaster position="top-right" />
      <h1 className="mb-4 iranfont text-center">داشبورد</h1>

      <div className="card mb-5">
        <div className="card-header">
          <h2 className="card-title iranfont" style={{ float: 'right' }}>
            اضافه کردن پست جدید
          </h2>
        </div>
        <div className="card-body">
          <div className="row">
            <button
              onClick={() => navigate('/posts/edit/0')}
              className="btn btn-success mt-2 iranfontregular"
            >
              پست جدید
            </button>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <h2 className="card-title iranfont" style={{ float: 'right' }}>
            تصاویر صفحه اول
          </h2>
        </div>
        <div className="card-body">
          <div className="row">
            {pageImages.map((image) => (
              <div key={`${image.id}-pageimage`} className="col-md-4 mb-4">
                <div className="card">
                  <img
                    src={image.name}
                    className="card-img-top"
                    alt={image.name}
                    style={{ height: '200px', objectFit: 'cover' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{`${image.name}`}</h5>
                    <div className="mb-2">
                      <input
                        type="number"
                        key={`inputorder-${image.id}`}
                        className="form-control"
                        placeholder="Enter order"
                        value={imageOrders[image.name]}
                        onChange={(e) =>
                          handleOrderChange(image.name, Number(e.target.value))
                        }
                      />
                    </div>
                    <button
                      className="btn btn-danger iranfontregular"
                      style={{ float: 'right' }}
                      onClick={() => handleDelete(image.name)}
                    >
                      حذف
                    </button>
                    <button
                      className="btn btn-success iranfontregular"
                      style={{ float: 'right' }}
                      onClick={() => handleImageUpdate(image.name)}
                    >
                      به روز رسانی
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <form onSubmit={handleSubmit} className="container mt-4">
              <label
                htmlFor="image"
                className="form-label iranfont mb-2"
                style={{ float: 'right' }}
              >
                اضافه کردن لینک عکس صفحه اول
              </label>
              <input
                type="text"
                className="form-control"
                id="imageName"
                value={imageName}
                onChange={(e) => setImageName(e.target.value)}
                placeholder="image url"
              />
              <button
                type="submit"
                className="btn btn-primary iranfont mb-4 mt-4"
              >
                اضافه کردن
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <h2 className="card-title iranfont" style={{ float: 'right' }}>
            مدیریت تصویرها
          </h2>
        </div>
        <div className="card-body">
          <div className="row">
            <Image />
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <h2 className="card-title iranfont" style={{ float: 'right' }}>
            مدیریت بخش ها
          </h2>
        </div>
        {sections.map((section) => (
          <div key={section.id} className="card mb-3">
            <div className="card-body">
              <>
                <h3 className="card-title">{section.name}</h3>
                <MDEditor
                  value={section.content}
                  onChange={(newContent) =>
                    handleSectionContentChange(section.id, newContent)
                  }
                  height={200}
                  // preview="edit"
                  textareaProps={{ placeholder: 'Content' }}
                />
                {section.image && (
                  <>
                    <div className="text-center mx-auto mt-4">
                      <LazyLoadImage
                        src={`${section.image}`}
                        className="card-img-top"
                        alt={`${section.image}`}
                        style={{ height: '200px', objectFit: 'cover' }}
                        effect="blur"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control mt-4"
                      id={`newsectionimage-${section.id}`}
                      placeholder="New section image"
                      onChange={(e) => {
                        handleImageInput(section.id, e.target.value);
                      }}
                    />
                  </>
                )}
                <button
                  onClick={() => handleSectionUpdate(section.id)}
                  className="btn btn-info mt-2 iranfontregular"
                  style={{ float: 'right' }}
                >
                  اصلاح
                </button>
              </>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Admin;
