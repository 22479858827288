const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="info-item  d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-map"></i>
              <h3 className="iranfont">آدرس ما</h3>
              <p className="iranfontregular">{process.env.REACT_APP_ADDRESS}</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-item d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-envelope"></i>
              <h3 className="iranfont">ارسال ایمیل</h3>
              <p>{process.env.REACT_APP_EMAIL}</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-item  d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-telephone"></i>
              <h3 className="iranfont">تماس</h3>
              <p>{process.env.REACT_APP_PHONE_NUMBER}</p>
            </div>
          </div>
        </div>

        <div className="row gy-4 mt-1">
          <div className="col">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6477.91944826418!2d51.315137717711046!3d35.72720903983197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1723578987974!5m2!1sen!2sus"
              width="600"
              height="450"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
