import React from 'react';

const Pagination = ({ itemCount, pageSize, currentPage, onPageChange }) => {
  const pageCount = Math.ceil(itemCount / pageSize);

  let pageCounts = [];
  for (let i = 0; i < pageCount; i++) {
    pageCounts.push(i + 1);
  }
  return (
    <nav aria-label="Page navigation iranfontregular mt-5">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            قبلی
          </button>
        </li>
        {pageCounts.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? 'active' : ''}`}
          >
            <button className="page-link" onClick={() => onPageChange(number)}>
              {number}
            </button>
          </li>
        ))}
        <li
          className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
        >
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === pageCount}
          >
            بعدی
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
