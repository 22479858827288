import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AboutForm from './AboutForm';

const EditAbout = () => {
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/sections/10`
        );
        setPost(postResponse.data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, []);

  const handleSubmit = async (postData) => {
    const url = `${process.env.REACT_APP_BACKEND}/sections/10`;
    const method = 'PUT';

    try {
      const response = await axios({
        method: method,
        url: url,
        data: postData,
        headers: { 'Content-Type': 'application/json' },
      });
      const data = response.data;
      // Handle success (e.g., show a success message, redirect)
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
      <h1
        style={{ margin: 'auto', width: '50%', textAlign: 'center' }}
        className="iranfont"
      >
        به روزرسانی پست
      </h1>
      <AboutForm post={post} onSubmit={handleSubmit} />
    </div>
  );
};

export default EditAbout;
