import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import carousel CSS
import './ProductCarousel.css';

const ProductCarousel = ({ items }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3, // Number of items to show at a time on desktop
      slidesToSlide: 1, // Slide one item at a time
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="row mt-5">
      <div className="col-md-12">
        <h4 className="iranfont text-center mx-auto">محصولات</h4>
        <Carousel
          responsive={responsive}
          infinite={true} // Loop the items
          autoPlay={true}
          autoPlaySpeed={4000} // Slide every 3 seconds
          keyBoardControl={true}
          showDots={false}
          containerClass="carousel-container"
          itemClass="carousel-item-padding-40-px"
          rtl={true}
          // arrows={false} // Remove navigation arrows
        >
          {items.map((item, index) => (
            <div key={index} className="product-item iranfontregular">
              <img src={item.image} alt={item.name} className="product-image" />
              <h4 className="iranfont">{item.name}</h4>
              <p style={{ fontSize: '0.9rem' }}>{item.description}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ProductCarousel;
