import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PostForm from './PostForm';
import './EditBlog.css';
import toast, { Toaster } from 'react-hot-toast';

const EditPost = () => {
  let { id } = useParams();
  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      if (id !== '0') {
        try {
          const postResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/posts-with-categories/${id}`
          );
          setPost(postResponse.data);
        } catch (error) {
          console.error('Error fetching post:', error);
        }
      }

      try {
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/categories`
        );
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
      setLoading(false);
    };

    fetchPost();
  }, [id]);

  const handleSubmit = async (postData) => {
    const url =
      id === '0'
        ? `${process.env.REACT_APP_BACKEND}/posts`
        : `${process.env.REACT_APP_BACKEND}/posts/${id}`;
    const method = id === '0' ? 'POST' : 'PUT';

    try {
      const response = await axios({
        method: method,
        url: url,
        data: postData,
        headers: { 'Content-Type': 'application/json' },
      });
      const data = response.data;
      toast.success('Section updated successfully!');
      // navigate(`/posts/edit/${id}`)
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(
        'Failed to update section. Please try again or contact the admin.'
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1
        style={{ margin: 'auto', width: '50%', textAlign: 'center' }}
        className="iranfont mb-4"
      >
        {id === '0' ? 'پست جدید' : 'به روزرسانی پست'}
      </h1>
      <Toaster position="top-right" />
      <PostForm post={post} categories={categories} onSubmit={handleSubmit} />
    </div>
  );
};

export default EditPost;
