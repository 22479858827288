import OurProject from './OurProject';
import Pagination from './Pagination';

const Posts = ({ title, posts, ticketCount, page, pageSize, onPageChange }) => {
  return (
    <section
      id="projects"
      className="projects"
      style={{ backgroundColor: 'rgb(245, 246, 247)' }}
    >
      <div className="container" data-aos="fade-up">
        <div className="section-header iranfontregular">
          <h2 className="iranfont text-center mb-5">{title}</h2>
          <div
            className="row gy-4 portfolio-container mb-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {posts &&
              posts.map((p, i) => (
                <OurProject
                  key={'post-item' + i}
                  Id={p.id}
                  Title={p.title}
                  Description={p.excerpt.slice(0, 100)}
                  ImageName={p.image_url}
                />
                // <ProjectSummary
                //   key={'post-item' + i}
                //   id={p.id}
                //   image_url={p.image_url}
                //   published_at={p.published_at}
                //   title={p.title}
                //   excerpt={p.excerpt}
                //   categories={p.categories}
                // />
              ))}
          </div>
          <Pagination
            itemCount={ticketCount}
            pageSize={pageSize}
            currentPage={page}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </section>
  );
};

export default Posts;
