import axios from 'axios';
import './Constructions.css';
import './Sections.css';
import {
  LazyLoadComponent,
  LazyLoadImage,
} from 'react-lazy-load-image-component';

import { useEffect, useState } from 'react';
const Constructions = () => {
  const [title, setTitle] = useState('');
  const [txt, setTxt] = useState('');
  const [h1, setH1] = useState('');
  const [b1, setB1] = useState('');
  const [i1, setI1] = useState('');
  const [h2, setH2] = useState('');
  const [b2, setB2] = useState('');
  const [i2, setI2] = useState('');
  const [h3, setH3] = useState('');
  const [b3, setB3] = useState('');
  const [i3, setI3] = useState('');
  const [h4, setH4] = useState('');
  const [b4, setB4] = useState('');
  const [i4, setI4] = useState('');

  useEffect(() => {
    const fetchingData = async () => {
      try {
        const [t, tx, sh1, sb1, sh2, sb2, sh3, sb3, sh4, sb4] =
          await Promise.all([
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/3`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/4`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/5`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/6`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/7`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/8`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/9`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/10`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/11`),
            axios.get(`${process.env.REACT_APP_BACKEND}/sections/12`),
          ]);

        setTitle(t.data.content);
        setTxt(tx.data.content);
        setH1(sh1.data.content);
        setB1(sb1.data.content);
        setI1(sb1.data.image);
        setH2(sh2.data.content);
        setB2(sb2.data.content);
        setI2(sb2.data.image);
        setH3(sh3.data.content);
        setB3(sb3.data.content);
        setI3(sb3.data.image);
        setH4(sh4.data.content);
        setB4(sb4.data.content);
        setI4(sb4.data.image);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchingData();
  }, []);

  return (
    <section
      id="constructions"
      className="constructions"
      style={{ backgroundColor: '#3498db' }}
    >
      <div
        className="container iranfontregular"
        data-aos="fade-up"
        style={{ direction: 'rtl' }}
      >
        <div className="section-header" style={{ direction: 'ltr' }}>
          <h2 className="iranfont">{title}</h2>
          <p className="iranfontregular" style={{ direction: 'rtl' }}>
            {txt}
          </p>
        </div>

        <div className="row gy-4">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <div className="card-item">
              <div className="row">
                <div className="col-xl-5 mx-auto my-auto text-center">
                  <LazyLoadImage
                    src={i1}
                    className="d-block mx-auto"
                    alt=""
                    effect="blur"
                    style={{
                      width: '100%',
                      maxHeight: '300px',
                    }}
                  />
                </div>
                <div className="col-xl-7 d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">{h1}</h4>
                    <p className="text-wrap">{b1}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
            <div className="card-item">
              <div className="row">
                <div className="col-xl-5 mx-auto my-auto text-center">
                  <LazyLoadImage
                    src={i2}
                    className="d-block mx-auto"
                    alt=""
                    effect="blur"
                    style={{
                      width: '100%',
                      maxHeight: '300px',
                    }}
                  />
                </div>
                <div className="col-xl-7 d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">{h2}</h4>
                    <p className="text-wrap">{b2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
            <div className="card-item">
              <div className="row">
                <div className="col-xl-5 mx-auto my-auto text-center">
                  <LazyLoadImage
                    src={i3}
                    className="d-block mx-auto"
                    alt=""
                    effect="blur"
                    style={{
                      width: '100%',
                      maxHeight: '300px',
                    }}
                  />
                </div>
                <div className="col-xl-7 d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">{h3}</h4>
                    <p>{b3}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
            <div className="card-item">
              <div className="row">
                <div className="col-xl-5 mx-auto my-auto text-center">
                  <LazyLoadImage
                    src={i4}
                    className="d-block mx-auto"
                    alt=""
                    effect="blur"
                    style={{
                      width: '100%',
                      maxHeight: '200px',
                    }}
                  />
                </div>
                <div className="col-xl-7 d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">{h4}</h4>
                    <p>{b4}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Constructions;
