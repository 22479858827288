import { useEffect, useState } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { Outlet } from 'react-router-dom';
import Footer2 from './components/Footer2';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FontLoader from './components/FondLoader';
import WhatsAppButton from './components/WhatsAppButton';

function App() {
  const [loaded, setLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
    document.fonts.ready.then(() => setLoaded(true));
  }, [isLoggedIn]);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <>
      <div className="container-fluid">
        {loaded === false ? (
          <div className="row">
            <div className=""></div>
          </div>
        ) : (
          <>
            <FontLoader fontName="iranyek" fontUrl="/assets/fonts/iranyek.woff">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <Navbar
                    isLoggedIn={isLoggedIn}
                    setIsLoggedIn={setIsLoggedIn}
                    handleLogin={handleLogin}
                    handleLogout={handleLogout}
                  />
                  <Outlet
                    context={{
                      isLoggedIn,
                      setIsLoggedIn,
                      handleLogin,
                      handleLogout,
                    }}
                  />
                  <Footer2 />
                </div>
              </div>
            </FontLoader>
            <WhatsAppButton />
          </>
        )}
      </div>
    </>
  );
}

export default App;
