import { useState, useEffect } from 'react';

const useFontLoader = (fontName, fontUrl) => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const font = new FontFace(fontName, `url(${fontUrl})`);
    const font2 = new FontFace(
      'iranyekbold',
      `url('/assets/fonts/iranyek-bold-extra.woff')`
    );

    font
      .load()
      .then(() => {
        document.fonts.add(font);
        setFontLoaded(true);
      })
      .catch((error) => {
        console.error('Failed to load font:', error);
        setFontLoaded(true); // Set to true even on error to prevent indefinite loading state
      });

    font2
      .load()
      .then(() => {
        document.fonts.add(font2);
        setFontLoaded(true);
      })
      .catch((error) => {
        console.error('Failed to load font:', error);
        setFontLoaded(true); // Set to true even on error to prevent indefinite loading state
      });
  }, [fontName, fontUrl]);

  return fontLoaded;
};

export default useFontLoader;
