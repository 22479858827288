import { Link } from 'react-router-dom';
import './Footer2.css';
import { ConvertToPersian } from './Utils';

const Footer2 = () => {
  return (
    <footer id="footer" className="footer" style={{ direction: 'rtl' }}>
      <div className="footer-content position-relative">
        <div className="container iranfontregular">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>تکسامش</h3>
                <p>
                  {process.env.REACT_APP_ADDRESS}
                  <br />
                  <br />
                  <strong>تلفن:</strong>{' '}
                  {ConvertToPersian(process.env.REACT_APP_PHONE_NUMBER)}
                  <br />
                  <strong>ایمیل:</strong>{' '}
                  <span style={{ fontFamily: 'sans-serif' }}>
                    {process.env.REACT_APP_EMAIL}
                  </span>
                  <br />
                </p>
                <div className="social-links d-flex mt-3">
                  {/* <a
                    href="#"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a
                    href="#"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bi bi-facebook"></i>
                  </a> */}
                  <a
                    href="https://instagram.com/Trc_iran"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                  {/* <a
                    href="#"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>لینک&zwnj;های مفید</h4>
              <ul>
                <li>
                  <Link to="/">خانه</Link>
                </li>
                <li>
                  <Link to="/projects">پروژه ها</Link>
                </li>
                <li>
                  <Link to="/blogs">مقاله ها</Link>
                </li>
                <li>
                  <Link to="/contact">تماس با ما</Link>
                </li>
                <li>
                  <Link to="/about">درباره ما</Link>
                </li>
              </ul>
            </div>

            {/* <div className="col-lg-2 col-md-3 footer-links">
              <h4>سرویس&zwnj;ها</h4>
              <ul>
                <li>
                  <a href="#">Web Design</a>
                </li>
                <li>
                  <a href="#">Web Development</a>
                </li>
                <li>
                  <a href="#">Product Management</a>
                </li>
                <li>
                  <a href="#">Marketing</a>
                </li>
                <li>
                  <a href="#">Graphic Design</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>وال&zwnj;مش</h4>
              <ul>
                <li>
                  <a href="#">Molestiae accusamus iure</a>
                </li>
                <li>
                  <a href="#">Excepturi dignissimos</a>
                </li>
                <li>
                  <a href="#">Suscipit distinctio</a>
                </li>
                <li>
                  <a href="#">Dilecta</a>
                </li>
                <li>
                  <a href="#">Sit quas consectetur</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>پروژه&zwnj;ها</h4>
              <ul>
                <li>
                  <a href="#">Ipsam</a>
                </li>
                <li>
                  <a href="#">Laudantium dolorum</a>
                </li>
                <li>
                  <a href="#">Dinera</a>
                </li>
                <li>
                  <a href="#">Trodelas</a>
                </li>
                <li>
                  <a href="#">Flexo</a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="footer-legal text-center position-relative iranfontregular"
        style={{ direction: 'rtl' }}
      >
        <div className="container">
          <div className="copyright">
            تمام حقوق اين وب‌سايت برای{' '}
            <strong>
              <span>{process.env.REACT_APP_COPYRIGHT}</span>
            </strong>{' '}
            است.
          </div>
          <div className="credits" style={{ fontFamily: 'sans-serif' }}>
            Designed by{' '}
            <a
              href="mailto:f.moradi.d@gmail.com"
              target="_parent"
              title="free css templates"
            >
              f.moradi.d@gmail.com
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer2;
