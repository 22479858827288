import { Link, useOutletContext } from 'react-router-dom';
import './OurProjects.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Project = (props) => {
  return (
    <div className={'col-lg-4 col-md-6 border rounded mx-3'}>
      <Link
        to={`/posts/${props.Id}`}
        title="More Details"
        className="details-link"
      >
        <div className="portfolio-content h-100 mx-auto text-center">
          <LazyLoadImage
            src={props.ImageName}
            className="d-block"
            alt=""
            effect="blur"
            style={{
              maxHeight: '300px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div className="portfolio-info">
            <h4 className="iranfont">{props.Title}</h4>
            {props.Description.length != 0 && (
              <p
                dir="rtl"
                className="iranfontregular"
                style={{ fontSize: 'small' }}
              >
                {props.Description.slice(0, 300)}
              </p>
            )}

            {/* <i className="bi bi-link-45deg"></i> */}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Project;
