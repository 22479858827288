import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Input from './form/Input';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { handleLogin } = useOutletContext();

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email === 'admin' && password === 'adminpass') {
      const token = 'admintoken';
      handleLogin(token);
      navigate('/');
    } else {
      // setAlertClassName('alert-danger');
      // setAlertMessage('Invalid credentials');
    }
  };

  return (
    <div className="row iranfont">
      <div className="col-md-4"></div>
      <div className="col-md-3">
        <h2 className="d-flex">ورود</h2>
        <form onSubmit={handleSubmit} className="mt-5">
          <Input
            title="نام کاربری"
            type="text"
            styleDirection="ltr"
            className="form-control englishfont text-left"
            name="phone"
            autoComplete="phone-new"
            onChange={(event) => setEmail(event.target.value)}
          />

          <Input
            title="کلمه عبور"
            type="password"
            styleDirection="ltr"
            className="form-control englishfont text-left"
            name="password"
            autoComplete="password-new"
            onChange={(event) => setPassword(event.target.value)}
          />

          <hr />

          <input
            type="submit"
            className="btn btn-info btn-block mb-5"
            value="ورود"
          />
        </form>
      </div>
      <div className="col-md-5"></div>
    </div>
  );
};

export default Login;
