import React, { useEffect, useState } from 'react';
import PostBody from './PostBody';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Post = () => {
  let { id } = useParams();
  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [postResponse, categoriesResponse] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_BACKEND}/posts-with-categories/${id}`
        ),
        axios.get(`${process.env.REACT_APP_BACKEND}/categories`),
      ]);
      setPost(postResponse.data);
      setCategories(categoriesResponse.data);
    };
    fetchData();

    window.scrollTo(0, 0);
  }, []);

  try {
    if (!post) {
      return <p style={{ minHeight: '90vh' }}></p>;
    }

    return (
      <>
        <PostBody post={post} categories={categories} />
      </>
    );
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export default Post;
