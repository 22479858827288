import React from 'react';
import ReactMarkdown from 'react-markdown';
import './PostBody.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';

const PostBody = (props) => {
  let dateObject = new Date(props.post.published_at);

  const { isLoggedIn } = useOutletContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let formattedDate = dateObject.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  let badgeColor = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
  ];
  const badgeCount = badgeColor.length;
  const categoriesCount = props.categories.length;
  for (let i = 0; i <= categoriesCount - badgeCount; i++) {
    badgeColor.push(badgeColor[i % (badgeCount + 1)]);
  }

  // return (
  //   <div className="container mb-8">
  //     <div className="row mb-8">
  //       <div className="col">
  //         <h2 className="mb-4 mx-auto">{props.post.title}</h2>
  //         <p className="text-center mx-auto">{formattedDate}</p>
  //       </div>
  //     </div>

  //     <div className="mb-8 row">
  //       <div className="col">
  //         <img
  //           src={props.post.image_url}
  //           alt={props.post.title}
  //           width={600}
  //           height={200}
  //           className="text-center mx-auto"
  //         />
  //       </div>
  //     </div>
  //     <div className="text-justify">
  //       <ReactMarkdown
  //         className="prose whitespace-pre-wrap"
  //         components={{
  //           p: ({ children }) => <p className="my-4">{children}</p>,
  //         }}
  //       >
  //         {props.post.content}
  //       </ReactMarkdown>
  //     </div>
  //     <div className="mb-4">
  //       <h3 className="mb-2">Categories:</h3>
  //       <div className="mb-4">
  //         {props.post.categories &&
  //           props.post.categories.map((c, i) => {
  //             return (
  //               <Link
  //                 to={`/categories?category=${
  //                   props.categories.find((category) => category.id === c)?.id
  //                 }`}
  //                 key={'category-item-summary-link' + i}
  //                 className="mr-2 mb-2"
  //               >
  //                 <span
  //                   className={`badge ${
  //                     badgeColor[
  //                       props.categories.find((category) => category.id === c)
  //                         ?.id
  //                     ]
  //                   }`}
  //                 >
  //                   {
  //                     props.categories.find((category) => category.id === c)
  //                       ?.name
  //                   }
  //                 </span>
  //               </Link>
  //             );
  //           })}
  //       </div>
  //     </div>
  //   </div>
  // );

  if (!props.post) {
    return <div style={{ minHeight: '90vh' }}>loading...</div>;
  }

  return (
    <div className="container post-view mt-5" dir="rtl">
      <div className="row">
        <div className="col">
          <h1 className="post-title">{props.post.title}</h1>
          {props.post.image_url && (
            <LazyLoadImage
              src={props.post.image_url}
              alt={props.post.title}
              className="img-fluid post-image mb-4"
              effect="blur"
            />
          )}
          {/* <div className="post-meta">
            <span className="post-date">{formattedDate}</span>

            {props.post.categories.map((c, i) => {
              return (
                <span
                  className={`badge bg-${
                    badgeColor[
                      props.categories.find((category) => category.id === c)?.id
                    ]
                  } me-2 iranfontregular`}
                >
                  {props.categories.find((category) => category.id === c)?.name}
                </span>
              );
            })}
          </div> */}
          {props.post.excerpt && (
            <div className="post-excerpt mb-4 iranfontregular">
              <ReactMarkdown>{props.post.excerpt}</ReactMarkdown>
            </div>
          )}
          <div className="post-content iranfontregular">
            <ReactMarkdown>{props.post.content}</ReactMarkdown>
          </div>
          {isLoggedIn && (
            <Link
              to={`/posts/edit/${props.post.id}`}
              className="iranfontregular btn btn-info mb-5"
            >
              اصلاح پست
            </Link>
            // TODO: add remove post
          )}
        </div>
      </div>
    </div>
  );
};

export default PostBody;
