import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './PostBody.css';
import axios from 'axios';

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const postResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/sections/2`
        );
        setPost(postResponse.data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <div style={{ minHeight: '90vh' }}></div>;
  }

  return (
    <div className="container post-view" dir="rtl">
      <div className="">
        <div className="col">
          <div
            className="post-content iranfontregular"
            style={{ minHeight: '90vh' }}
          >
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
