import React, { useState } from 'react';

const UploadImage = () => {
  const [file, setFile] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/images`, {
        method: 'POST',
        body: formData,
      });

      setUploading(false);

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const data = await response.json();
      setUuid(data.filePath);
    } catch (error) {
      if (error instanceof Error) {
        console.log(error);
        setError(error.message);
      }
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-6 mx-auto text-center">
        <h2 className="iranfont mt-5">بارگذاری تصویر</h2>
        {error && <p className="text-danger">{error}</p>}
        <div className="">
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="row">
              <input
                id="picture"
                type="file"
                className="text-center"
                onChange={handleFileChange}
              />
            </div>
            <div className="row">
              <button
                type="submit"
                className="btn btn-primary mt-5 iranfontregular"
              >
                {!uploading ? 'بارگذاری' : '... در حال بارگذاری'}
              </button>
            </div>
          </form>
        </div>
        {uuid && (
          <div className="mt-10">
            <hr />
            <img
              src={`${process.env.REACT_APP_BACKEND}/${uuid}`}
              alt="Uploaded"
              style={{ maxWidth: '500px' }}
              className="mt-10"
            />
            <p className="mt-10">
              Path: {`${process.env.REACT_APP_BACKEND}/${uuid}`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImage;
