import React from 'react';
import useFontLoader from './useFontLoader';

const FontLoader = ({ children, fontName, fontUrl }) => {
  const fontLoaded = useFontLoader(fontName, fontUrl);

  if (!fontLoaded) {
    // return <div style={{ minHeight: '100vh', backgroundColor: 'brown' }}></div>;
    return (
      <div style={{ minHeight: '100vh', objectFit: 'cover' }}>
        <img src="https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg" />
      </div>
    );
  }

  return <>{children}</>;
};

export default FontLoader;
