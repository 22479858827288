import { useEffect, useState } from 'react';
import './Home.css';
import Header from './Header';
import RecentBlogs from './RecentBlogs';
import OurServices from './OurServies';
import WorkSample from './WorkSample';
import Constructions from './Constructions';
import OurProjects from './OurProjects';
import PDFViewer from './PDFViewer';
import CarouselList from './ProductCarousel';
import ProductCarousel from './ProductCarousel';
const Home = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoaded(true);
  }, []);

  const products = [
    {
      name: 'اتصالات فلزی پرین',
      description:
        'ابزار کار وسایل و ابزاری هستند که برای اجرای ملات ها و بلوک مورد نیاز می باشند.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'ابزار کار پرین',
      description:
        'ابزار کار وسایل و ابزاری هستند که برای اجرای ملات ها و بلوک مورد نیاز می باشند.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'الیاف شیشه پرین',
      description:
        'الیاف شیشه مقاوم در برابر قلیا (AR-Glass) یک محصول بهینه در ساخت و سازهای عمرانی بوده که به طور معمول از آن به عنوان لایه مقاوم کننده در آب بندی ها، مسلح کردن، دیوارها و پوشش های گچی یا سیمانی استفاده می شود.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'اتصالات فلزی پرین',
      description:
        'ابزار کار وسایل و ابزاری هستند که برای اجرای ملات ها و بلوک مورد نیاز می باشند.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'ابزار کار پرین',
      description:
        'ابزار کار وسایل و ابزاری هستند که برای اجرای ملات ها و بلوک مورد نیاز می باشند.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'الیاف شیشه پرین',
      description:
        'الیاف شیشه مقاوم در برابر قلیا (AR-Glass) یک محصول بهینه در ساخت و سازهای عمرانی بوده که به طور معمول از آن به عنوان لایه مقاوم کننده در آب بندی ها، مسلح کردن، دیوارها و پوشش های گچی یا سیمانی استفاده می شود.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'اتصالات فلزی پرین',
      description:
        'ابزار کار وسایل و ابزاری هستند که برای اجرای ملات ها و بلوک مورد نیاز می باشند.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'ابزار کار پرین',
      description:
        'ابزار کار وسایل و ابزاری هستند که برای اجرای ملات ها و بلوک مورد نیاز می باشند.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'الیاف شیشه پرین',
      description:
        'الیاف شیشه مقاوم در برابر قلیا (AR-Glass) یک محصول بهینه در ساخت و سازهای عمرانی بوده که به طور معمول از آن به عنوان لایه مقاوم کننده در آب بندی ها، مسلح کردن، دیوارها و پوشش های گچی یا سیمانی استفاده می شود.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'اتصالات فلزی پرین',
      description:
        'ابزار کار وسایل و ابزاری هستند که برای اجرای ملات ها و بلوک مورد نیاز می باشند.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'ابزار کار پرین',
      description:
        'ابزار کار وسایل و ابزاری هستند که برای اجرای ملات ها و بلوک مورد نیاز می باشند.',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
    {
      name: 'الیاف شیشه پرین',
      description:
        'الیاف شیشه مقاوم در برابر قلیا (AR-Glass) یک محصول بهینه در ساخت و سازهای عمرانی بوده که به طور معمول از آن به عنوان لایه مقاوم کننده در آب بندی ها، مسلح کردن، دیوارها و پوشش های گچی یا سیمانی استفاده می شود. ',
      image:
        'https://api.taksamesh.com/uploads/fb03b78c-afdc-4875-9d15-d84fc3d0fdbc.jpg',
    },
  ];

  return (
    <>
      {loaded === false ? (
        <p>loading...</p>
      ) : (
        <>
          <>
            <Header />
            <hr />
            <RecentBlogs />
            <WorkSample />
            <Constructions />
            <ProductCarousel items={products} />
            <OurProjects />
            <OurServices />
          </>
        </>
      )}
    </>
  );
};

export default Home;
