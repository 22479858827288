import React, { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';

const PostForm = ({ post, categories, onSubmit }) => {
  const [title, setTitle] = useState('');
  const [imageName, setImageName] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [content, setContent] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (post) {
      setTitle(post.title || '');
      setImageName(post.image_url || '');
      setExcerpt(post.excerpt || '');
      setContent(post.content || '');
      setSelectedCategories(post.categories || []);
    }
  }, [post]);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      title,
      image_url: imageName,
      excerpt,
      content,
      categories: selectedCategories,
    };
    onSubmit(postData);
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-4">
      <div className="mb-3">
        <label htmlFor="title" className="form-label iranfont mb-2">
          عنوان
        </label>
        <MDEditor
          value={title}
          onChange={setTitle}
          preview="edit"
          textareaProps={{ placeholder: 'Title' }}
        />
      </div>

      <hr />

      <div className="mb-3">
        <label htmlFor="image" className="form-label iranfont mb-2">
          عکس پست جدید
        </label>
        <input
          type="text"
          className="form-control"
          id="imageName"
          value={imageName}
          onChange={(e) => setImageName(e.target.value)}
          placeholder="image url"
        />
      </div>

      <hr />

      <div className="mb-3">
        <label htmlFor="excerpt" className="form-label iranfont mb-2">
          توضیح کوتاه
        </label>
        <MDEditor
          value={excerpt}
          onChange={setExcerpt}
          preview="edit"
          textareaProps={{ placeholder: 'Excerpt' }}
        />
      </div>

      <hr />

      <div className="mb-3">
        <label htmlFor="content" className="form-label iranfont mb-2">
          متن
        </label>
        <MDEditor
          value={content}
          onChange={setContent}
          height={400}
          textareaProps={{ placeholder: 'Content' }}
        />
      </div>

      <hr />

      <div className="mb-3" style={{ direction: 'rtl' }}>
        {categories.map((category) => (
          <div key={category.id}>
            <input
              type="checkbox"
              id={`category-${category.id}`}
              checked={selectedCategories.includes(category.id)}
              onChange={() => handleCategoryChange(category.id)}
            />
            <span> </span>
            <label
              className="iranfontregular ml-4"
              htmlFor={`category-${category.id}`}
            >
              {category.name}
            </label>
          </div>
        ))}
      </div>

      <button type="submit" className="btn btn-primary iranfont mb-4">
        {post ? 'به روزرسانی' : 'ایجاد'}
      </button>
    </form>
  );
};

export default PostForm;
