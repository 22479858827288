import React from 'react';
import UploadImage from './UploadImage';
import ImageList from './ImageList';
import { Link } from 'react-router-dom';

const Image = () => {
  return (
    <div>
      <div className="container mt-4">
        <div className="row mx-auto text-center">
          <Link to="/uploadwatermark">
            <button className="btn btn-success mt-2 iranfontregular">
              اضافه کردن عکس به همراه واترمارک
            </button>
          </Link>
        </div>
        <hr />
        <div className="row mx-auto text-center">
          <Link to="/upload">
            <button className="btn btn-success mt-2 iranfontregular">
              اضافه کردن عکس بدون واترمارک
            </button>
          </Link>
        </div>
        <hr />
        <ImageList />
      </div>
    </div>
  );
};

export default Image;
