import { useEffect, useRef, useState } from 'react';
import './Header.css';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

function Header(props) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/pageimages`
        );
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="row mt-2">
      <div className="col-md-12">
        <div
          id="carouselExample"
          className="carousel slide carousel-container-header mx-auto text-center"
        >
          <div className="carousel-inner" style={{ direction: 'rtl' }}>
            {images.map((image, index) => (
              <div
                className={
                  index === 0 ? 'carousel-item active' : 'carousel-item'
                }
              >
                <LazyLoadImage
                  key={`lazy-image-${image.id}`}
                  src={image.name}
                  effect="opacity"
                  width="100%"
                  height="100%"
                  className="d-block w-100 carousel-image"
                />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
