import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './Navbar.css';

function Navbar({ isLoggedIn, setIsLoggedIn, handleLogin, handleLogout }) {
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const visible = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    let timeoutId = null;
    const throttledHandleScroll = () => {
      if (timeoutId === null) {
        timeoutId = setTimeout(() => {
          handleScroll();
          timeoutId = null;
        }, 100);
      }
    };

    window.addEventListener('scroll', throttledHandleScroll);

    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, [prevScrollPos]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <div className="row" style={{ position: 'relative' }}>
      <div
        className={`col-md-12 col-sm-12 col-12 navbar-sticky ${
          visible ? '' : 'hidden'
        }`}
        style={{
          direction: 'rtl',
          backgroundColor: '#191d33',
        }}
      >
        {isLoggedIn ? (
          <div style={{ float: 'left' }} className="badge bg-danger mt-3">
            <Link
              onClick={handleLogout}
              className="iranfontregular "
              style={{ fontSize: 'medium' }}
            >
              خروج
            </Link>
          </div>
        ) : (
          <div style={{ float: 'left' }} className="badge bg-dark mt-3">
            <Link
              to="/login"
              className="iranfontregular"
              style={{ fontSize: 'medium', color: 'rgb(248, 157, 36)' }}
            >
              ورود
            </Link>
          </div>
        )}
        <nav
          className="navbar navbar-dark navbar-expand-lg"
          style={{ backgroundColor: '#191d33' }}
        >
          <div className="container-fluid">
            <Link href="/">
              <LazyLoadImage
                className="border border-light rounded"
                src="/logo512.png"
                style={{
                  width: '50px',
                  height: '50px',
                  float: 'right',
                }}
                alt="#"
                effect="blur"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleNavbar}
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={isOpen}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              // className="collapse navbar-collapse"
              className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0 iranfontbold">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    onClick={closeNavbar}
                    aria-current="page"
                    to="/"
                  >
                    خانه
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <a className="nav-link" aria-current="page" href="#">
                    سرویس&zwnj;ها
                  </a>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    وال&zwnj;مش
                  </Link>
                  <ul className="dropdown-menu iranfontregular">
                    <li>
                      <Link className="dropdown-item" to="#">
                        وال&zwnj;مش چیست
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">
                        قیمت وال&zwnj;مش
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">
                        ویژگی&zwnj;های وال&zwnj;مش
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    onClick={closeNavbar}
                    to="/projects"
                  >
                    پروژه&zwnj;ها
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    onClick={closeNavbar}
                    to="/blogs"
                  >
                    مقاله&zwnj;ها
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    onClick={closeNavbar}
                    to="/about"
                  >
                    درباره&zwnj;ی ما
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    onClick={closeNavbar}
                    to="/contact"
                  >
                    ارتباط با ما
                  </Link>
                </li>

                {isLoggedIn && (
                  <li className="nav-item">
                    <Link
                      to="/admin"
                      className="nav-link"
                      onClick={closeNavbar}
                    >
                      مدیریت سایت
                    </Link>
                  </li>
                )}
              </ul>
              {/* <form className="d-flex" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </form> */}
            </div>
          </div>
        </nav>
      </div>
      <div className="navbar-placeholder"></div>
    </div>
  );
}
export default Navbar;
