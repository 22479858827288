import './OurServices.css';
import './Sections.css';
import { ConvertNumberToPersionUnit } from './Utils';
import axios from 'axios';
import { useEffect, useState } from 'react';
const OurServices = () => {
  const [ourservice, setOurservice] = useState('');
  const [ourservice1Header, setOurservice1Header] = useState('');
  const [ourservice1Body, setOurservice1Body] = useState('');
  const [ourservice2Header, setOurservice2Header] = useState('');
  const [ourservice2Body, setOurservice2Body] = useState('');
  const [ourservice3Header, setOurservice3Header] = useState('');
  const [ourservice3Body, setOurservice3Body] = useState('');

  useEffect(() => {
    const fetchingData = async () => {
      try {
        const [s, sh1, sb1, sh2, sb2, sh3, sb3] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/sections/13`),
          axios.get(`${process.env.REACT_APP_BACKEND}/sections/14`),
          axios.get(`${process.env.REACT_APP_BACKEND}/sections/15`),
          axios.get(`${process.env.REACT_APP_BACKEND}/sections/16`),
          axios.get(`${process.env.REACT_APP_BACKEND}/sections/17`),
          axios.get(`${process.env.REACT_APP_BACKEND}/sections/18`),
          axios.get(`${process.env.REACT_APP_BACKEND}/sections/19`),
        ]);

        setOurservice(s.data.content);
        setOurservice1Header(sh1.data.content);
        setOurservice1Body(sb1.data.content);
        setOurservice2Header(sh2.data.content);
        setOurservice2Body(sb2.data.content);
        setOurservice3Header(sh3.data.content);
        setOurservice3Body(sb3.data.content);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchingData();
  }, []);

  return (
    <div className="row" id="ourService">
      <div className="col-md-12">
        <section id="services" className="services">
          <div
            className="container iranfontregular"
            style={{ direction: 'rtl' }}
            data-aos="fade-up"
          >
            <div className="section-header" style={{ direction: 'ltr' }}>
              <h2 className="iranfont">خدمات</h2>
              <p style={{ direction: 'rtl' }}>{ourservice}</p>
            </div>

            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="service-item  position-relative">
                  <div className="icon">
                    <i className="fa-solid fa-mountain-city"></i>
                  </div>
                  <h3>{ourservice1Header}</h3>
                  <p>{ourservice1Body}</p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid fa-arrow-up-from-ground-water"></i>
                  </div>
                  <h3>{ourservice2Header}</h3>
                  <p>{ourservice2Body}</p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid fa-compass-drafting"></i>
                  </div>
                  <h3>{ourservice3Header}</h3>
                  <p>{ourservice3Body}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurServices;
