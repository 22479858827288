import React, { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { useNavigate } from 'react-router-dom';

const AboutForm = ({ post, onSubmit }) => {
  const [content, setContent] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (post) {
      setContent(post.content || '');
    }
  }, [post]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      content,
    };
    onSubmit(postData);
    navigate('/about');
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-4">
      <div className="mb-3">
        <label htmlFor="content" className="form-label iranfont mb-2">
          متن
        </label>
        <MDEditor
          value={content}
          onChange={setContent}
          height={400}
          textareaProps={{ placeholder: 'Content' }}
        />
      </div>

      <button type="submit" className="btn btn-primary iranfont">
        به روزرسانی
      </button>
    </form>
  );
};

export default AboutForm;
