import { Link } from 'react-router-dom';
import OurProject from './OurProject';
import './OurProjects.css';
import './Sections.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

const OurProjects = () => {
  const [description, setDescription] = useState('');
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultSection = await axios.get(
          `${process.env.REACT_APP_BACKEND}/sections/1`
        );
        setDescription(resultSection.data.content);

        const result = await axios.get(
          `${
            process.env.REACT_APP_BACKEND
          }/categories/posts?limit=${6}&offset=${0}&category=2`
        );
        setPosts(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section
      id="projects"
      className="projects section-bg"
      style={{ minHeight: '70vh' }}
    >
      <div className="container" data-aos="fade-up">
        <div className="section-header iranfontregular">
          <Link to="/projects">
            <h2 className="iranfont">پروژه&zwnj;های ما</h2>
          </Link>
          <p dir="rtl">{description}</p>
        </div>

        <div>
          <div
            className="row gy-4 portfolio-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {posts.map((p, m) => (
              <OurProject
                key={`prj-${p.id}`}
                Id={p.id}
                Title={p.title}
                Description={p.excerpt}
                ImageName={p.image_url}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProjects;
