import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, useOutletContext } from 'react-router-dom';

const ImageList = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchImages();
  }, []);

  const navigate = useNavigate();

  const { isLoggedIn } = useOutletContext();
  if (!isLoggedIn) {
    navigate('/login');
  }

  const fetchImages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/imagelist`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }
      const data = await response.json();
      setImages(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/imagelist/${id}`,
        { method: 'DELETE' }
      );
      if (!response.ok) {
        throw new Error('Failed to delete image');
      }
      setImages(images.filter((img) => img.id !== id));
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4 mx-auto text-center iranfont">لیست تصویرها</h2>
      <div className="row">
        {images.map((image) => (
          <div key={`${image.id}-imagelist`} className="col-md-4 mb-4">
            <div className="card">
              <LazyLoadImage
                src={`${process.env.REACT_APP_BACKEND}${image.url}`}
                className="card-img-top"
                alt={`${process.env.REACT_APP_BACKEND}${image.url}`}
                style={{ height: '200px', objectFit: 'cover' }}
                effect="blur"
              />
              <div className="card-body">
                <h5 className="card-title">{`${process.env.REACT_APP_BACKEND}/uploads/${image.name}`}</h5>
                <button
                  className="btn btn-danger iranfontregular"
                  style={{ float: 'right' }}
                  onClick={() => handleDelete(image.id)}
                >
                  حذف
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageList;
