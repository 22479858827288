const persian = {
  0: '۰',
  1: '۱',
  2: '۲',
  3: '۳',
  4: '۴',
  5: '۵',
  6: '۶',
  7: '۷',
  8: '۸',
  9: '۹',
};

const english = {
  '۰': '0',
  '۱': '1',
  '۲': '2',
  '۳': '3',
  '۴': '4',
  '۵': '5',
  '۶': '6',
  '۷': '7',
  '۸': '8',
  '۹': '9',
};

export function ConvertNumberToEnglishUnitFloat(n) {
  return n
    .split('')
    .map((i) => {
      if (i === '/') {
        return '.';
      } else if (
        i === '۰' ||
        i === '۱' ||
        i === '۲' ||
        i === '۳' ||
        i === '۴' ||
        i === '۵' ||
        i === '۶' ||
        i === '۷' ||
        i === '۸' ||
        i === '۹'
      ) {
        return english[i];
      } else {
        return i;
      }
    })
    .join('');
}

export function ConvertNumberToPersion(n) {
  return numberWithCommas(
    n
      .split('')
      .map((i) => persian[parseInt(i)])
      .join('')
  );
}

export function ConvertNumberToPersionUnit(n) {
  return numberWithCommasUnit(
    n
      .split('')
      .map((i) => {
        if (i === '.') {
          return '.';
        } else {
          return persian[parseInt(i)];
        }
      })
      .join('')
  );
}

export function ConvertNumberToPersionUnitFloat(n) {
  return numberWithCommasUnit(
    n
      .split('')
      .map((i) => {
        if (i === '/') {
          return '/';
        } else {
          return persian[i];
        }
      })
      .join('')
  );
}

// without the corrency unit at the end
function numberWithCommasUnit(x) {
  let counter = 1;
  let temp;
  let start;
  if (x.lastIndexOf('.') === -1) {
    temp = '';
    start = x.length - 1;
  } else {
    temp = x.slice(x.lastIndexOf('.'), x.length);
    start = x.lastIndexOf('.') - 1;
  }
  for (let i = start; i >= 0; i--, counter++) {
    temp = x[i] + temp;
    if (counter % 3 === 0 && counter != x.length) {
      temp = ',' + temp;
    }
  }
  return temp;
}

function numberWithCommas(x) {
  let counter = 1;
  let temp = '';
  for (let i = x.length - 1; i >= 0; i--, counter++) {
    temp = x[i] + temp;
    if (counter % 3 === 0 && counter != x.length) {
      temp = ',' + temp;
    }
  }
  temp = temp + ' ' + 'تومان';
  return temp;
}

export function ConvertToPersian(x) {
  let counter = 1;
  let temp = '';
  for (let i = x.length - 1; i >= 0; i--, counter++) {
    temp = persian[x[i]] + temp;
  }
  return temp;
}
