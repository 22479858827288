import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import ErrorPage from './components/ErrorPage';
import Home from './components/Home';
import Login from './components/Login';
import Blogs from './components/Blogs';
import Projects from './components/Projects';
import EditPost from './components/EditPost';
import Post from './components/Post';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import EditAbout from './components/EditAbout';
import UploadImage from './components/UploadImage';
import UploadImageRegular from './components/UploadImageRegular';
import ImageList from './components/ImageList';
import Admin from './components/Admin';
import Image from './components/Image';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/posts/:id',
        element: <Post />,
      },
      {
        path: '/blogs',
        element: <Blogs />,
      },
      {
        path: '/projects',
        element: <Projects />,
      },
      {
        path: '/admin',
        element: <Admin />,
      },
      {
        path: '/admin/image',
        element: <Image />,
      },
      {
        path: '/posts/edit/:id',
        element: <EditPost />,
      },
      {
        path: '/about/edit/',
        element: <EditAbout />,
      },
      {
        path: '/about',
        element: <AboutUs />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '/imagelist',
        element: <ImageList />,
      },
      {
        path: '/uploadwatermark',
        element: <UploadImage />,
      },
      {
        path: '/upload',
        element: <UploadImageRegular />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
