import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Posts from './Posts';
import './OurProjects.css';
import './Sections.css';

const Projects = () => {
  const [posts, setPosts] = useState([]);
  const [postsCount, setPostsCount] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 12;
  const offset = (page - 1) * pageSize;
  useEffect(() => {
    const fetchingData = async () => {
      try {
        const [postsData, result] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_BACKEND}/categories/posts?limit=${pageSize}&offset=${offset}&category=2`
          ),
          axios.get(`${process.env.REACT_APP_BACKEND}/counts`),
        ]);

        setPosts(postsData.data);
        setPostsCount(parseInt(result.data));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchingData();
  }, [page, pageSize, offset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    // Fetch data for the new page or update your UI accordingly
  };

  return (
    <Posts
      title="پروژه ها"
      posts={posts}
      ticketCount={postsCount}
      page={page}
      pageSize={pageSize}
      onPageChange={handlePageChange}
    />
  );
};

export default Projects;
