import React from 'react';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  return (
    <div className="whatsapp-button">
      <a
        href="https://wa.me/09125082433"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="bi bi-whatsapp text-large"></i>
      </a>
    </div>
  );
};

export default WhatsAppButton;
