import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import OurProject from './OurProject';
import './OurProjects.css';
import './Sections.css';

const RecentBlogs = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${
            process.env.REACT_APP_BACKEND
          }/categories/posts?limit=${6}&offset=${0}&category=1`
        );
        setPosts(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section
      id="bloags"
      className="projects p-0 mb-3"
      style={{ minHeight: '50vh' }}
    >
      <div className="container" data-aos="fade-up">
        <div className="section-header iranfontregular">
          <Link to="/blogs">
            <h2 className="iranfont">اخبار و مقالات برگزیده</h2>
            {/* <p>a title for last articles</p>{' '} */}
          </Link>
        </div>

        <div>
          <div
            className="row gy-4 portfolio-container p-0"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {posts.map((p, m) => (
              <OurProject
                key={`prj-${p.id}`}
                Id={p.id}
                Title={p.title}
                Description={p.excerpt}
                ImageName={p.image_url}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentBlogs;
